import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { BiSearchAlt2 as SearchIcon } from "@react-icons/all-files/bi/BiSearchAlt2";
import { BiWorld as WorldIcon } from "@react-icons/all-files/bi/BiWorld";

import { createFilter } from "react-select";
import { Config as FilterConfig } from "react-select/src/filters";

import { ACTION_TYPES, createAction, getCountryDataFromTimezone } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { setLocalStorageItem } from "repoV2/utils/common/storage/getterAndSetters";
import {
    DEFAULT_TIMEZONE,
    TIMEZONE_DATA_LIST,
    TIMEZONE_LOCAL_STORAGE_KEYS,
} from "repoV2/features/Common/modules/Timezone/Timezone.constants";
import { getTimezone } from "@Utils/getTimezone";
import { DropdownSelector } from "../Form";
import { IDropdownSelector } from "../Form/FormInputs/DropdownSelector/IDropdownSelector";
import { ISelectTimezone } from "./ISelectTimezone";
import styles from "./selectTimezone.module.scss";

const SelectTimezone = ({
    onChange,
    alwaysRefetch = {},
}: ISelectTimezone.IProps) => {
    const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
    const [isSelected, setSelected] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setTimezone(getTimezone().timezone);
    }, []);

    const options: ISelectTimezone.IOption[] = TIMEZONE_DATA_LIST.map(tz => ({
        label: `${tz.name} (${tz.offset.slice(0, 3)}:${tz.offset.slice(3, 5)})`,
        value: tz.name,
        searchString: [
            tz.country_code,
            tz.name,
            ...tz.name.split("/"),
            tz.offset,
            tz.offset.slice(1),
            tz.offset.slice(0, 3),
            tz.offset.slice(3, 5),
            `${tz.offset.slice(0, 3)}:${tz.offset.slice(3, 5)}`,
        ].join(" "),
    }));

    const handleChange = (e: IDropdownSelector.Option | null) => {
        if (!e) return;
        if (e.value === timezone) return;
        setTimezone(e.value);
        setLocalStorageItem(TIMEZONE_LOCAL_STORAGE_KEYS.USER_TIMEZONE, e.value);

        const { countryCode, countryName } = getCountryDataFromTimezone(
            e.value
        );

        dispatch(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_LOCALE, {
                timezone: e.value,
                countryCode,
                countryName,
            })
        );
        dispatch(createAction(ACTION_TYPES.HOST.REFETCH_DATA, alwaysRefetch));
        onChange?.();
    };

    const PreIcon = isSelected ? SearchIcon : WorldIcon;

    // https://react-select.com/advanced#custom-filter-logic
    const filterConfig: FilterConfig = {
        matchFrom: "any",
        ignoreCase: true,
        stringify: (o: ISelectTimezone.IOption) => o.searchString,
    };

    return (
        <div className={styles.root}>
            <PreIcon className={styles.icon} />
            &nbsp;
            <DropdownSelector
                className={classNames(
                    styles.dropdown,
                    isSelected && styles.selected
                )}
                options={options}
                onChange={handleChange}
                filterOptions={createFilter(filterConfig)}
                value={
                    isSelected ? null : options.find(o => o.value === timezone)
                }
                onFocus={() => {
                    setSelected(true);
                }}
                onBlur={() => setSelected(false)}
                placeholder={`Search (${timezone} selected)`}
                blurInputOnSelect
            />
        </div>
    );
};

export default SelectTimezone;
